<script>
import { EntityProcessor } from '/~rec/core/entity-processor'
import { RecroomTransaction } from '/~rec/core/transaction'
import RecognitionHeader from '/~rec/components/recognition/recognition-header.vue'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import TokenTransactionTile from './components/token-transaction-tile.vue'
import BaseHeader from '/~/components/base/header/base-header.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { formatNumber } from '/~/utils/format/numeric'

export default {
  name: 'rec-token-activities-modal-mobile',
  components: {
    RecognitionHeader,
    EwCatalogProcessor,
    TokenTransactionTile,
    BaseHeader,
    BaseMdl,
  },
  props: {
    tokenBalance: {
      type: Number,
      default: 0,
    },
    currency: {
      type: String,
      default: 'Token',
    },
  },
  setup() {
    return {
      formatNumber,
    }
  },
  data() {
    return {
      processor: new EntityProcessor({
        entity: 'gamification/transaction-logs',
        perPage: 25,
        mapping: (record) => new RecroomTransaction(record),
      }),
    }
  },
  created() {
    this.processor.getData()
  },
}
</script>

<template>
  <base-mdl fullscreen="mobile" :padding="false">
    <template #header>
      <base-header class="text-white" type="closable">
        <recognition-header color="warning" icon="rec/round/token" animated />
      </base-header>
    </template>
    <h2 class="mb-5 text-center text-2xl">
      My
      <span class="capitalize">
        {{ currency }}
      </span>
      balance {{ formatNumber(tokenBalance) }}
    </h2>
    <ew-catalog-processor
      :processor="processor"
      :columns="1"
      :tile-class="false"
    >
      <template #empty>
        <div class="px-5">No Transaction logs found.</div>
      </template>
      <template #tile="{ tile: transaction }">
        <token-transaction-tile :transaction="transaction" />
      </template>
    </ew-catalog-processor>
  </base-mdl>
</template>
